import { Injectable } from '@angular/core';
import Swal from "sweetalert2";
import { Workbook } from "exceljs";
import { excelPost } from '../../models/excelPost';
import * as fs from "file-saver";
import { FirestoreService } from '../firestore/firestore.service';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})

export class CommonService {

  dataClone: any[] = [];
  productoEMMI: any = {
    productExtras: {},
    productFlete: {}
  };

  constructor(
    private workbook: Workbook,
    private firestore: FirestoreService,
    private http: HttpService,
  ) {
  }

  /** 
   *          _________________________________________
   *         |                                         |
   *         | POST PARA LA EXPORTACIÓN A EXCEL .XLSX  |
   *         |_________________________________________|
   *         
   * dataTable = {
   *    title: string,                  nombre del documento y hoja en .xlsx
   *    clientName: string,             nombre del cliente
   *    columns: Array [{               edicion de las columnas
   *        name: string,               ubicación de las columnas Eje: A, B, C ...
   *        width: number               largo de la celda 
   *    }],
   *    columnsMerge: Array [{          fusión de columnas
   *        name: string,               ubicación de las columnas Eje: A1:B1, C1:D1 ...
   *    }],
   *    cellTitleFormats: Array,        estilo del ecabezado Eje: ['B2', 'D2']
   *    headerData: Object {            info del encabezado
   *        headerColumn: string,       edicion del ecabezado Eje: A1:B1
   *        row: number,                número de fila del encabezado
   *        height: number              Alto del encabezado
   *    },
   *    subHeaders: Object {            info de los encabezados de la tabla
   *        row: number,                número de fila de los encabezados de la tabla
   *        data: Array                 encabezados de la tabla partiendo de la primera celda de la fila Eje: ["", "Name", "Email", "Phone", "Validated phone", "Date Register"]
   *        cellFormats: Array,         estilos de los encabezados de la tabla partiendo de la primera celda de la fila Eje: ['B3', 'C3', 'D3', 'E3', 'F3']
   *        color: string,              color de fondo para los encabezados de la tabla
   *    }
   *    dataClone: Array,               información de la tabla columnas segun el numero de encabezados
   *    bodyData: Object {              info del cuerpo
   *        row: number,                número de fila donde inicia el cuerpo de la información
   *        height: number              Alto de cada fila del cuerpo
   *    },
   * }
  **/

  exportTableToExcelJS(dataTable: excelPost) {
    this.dataClone = dataTable.dataClone;
    if (this.dataClone && this.dataClone.length > 0) {
      const title = dataTable.title;
      this.workbook = new Workbook();
      this.workbook.creator = title;
      this.workbook.lastModifiedBy = dataTable.clientName;
      this.workbook.created = new Date();

      this.createTable(dataTable);

      this.workbook.xlsx.writeBuffer().then(data => {
        const blob = new Blob([data]);
        fs.saveAs(blob, title + '.xlsx')
      })
    } else {
      Swal.fire('Opps', `information not found`, 'info');
    }
  }

  createTable(dataTable): void {
    const sheet = this.workbook.addWorksheet(dataTable.title);

    dataTable.columns.forEach(element => {
      sheet.getColumn(element.name).width = element.width;
    });

    dataTable.columnsMerge.forEach(element => {
      sheet.mergeCells(element.name);
    });

    const titleCell = sheet.getCell(dataTable.headerData.headerColumn);
    titleCell.value = dataTable.headerTitle;
    titleCell.style.font = { bold: true, size: 17 };
    titleCell.style.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };

    const titleCell2 = sheet.getCell(dataTable.headerData2.headerColumn);
    titleCell2.value = dataTable.clientName;
    titleCell2.style.font = { bold: true, size: 17 };
    titleCell2.style.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };

    const cellTitleFormats = dataTable.cellTitleFormats;

    cellTitleFormats.forEach(cell => {
      const titleCellFormat = sheet.getCell(cell);
      titleCellFormat.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    });

    sheet.getRow(dataTable.headerData.row).height = dataTable.headerData.height;

    const headerRow = sheet.getRow(dataTable.subHeaders.row);
    headerRow.values = dataTable.subHeaders.data;

    const cellFormats = dataTable.subHeaders.cellFormats;

    cellFormats.forEach(cell => {
      const titleCellFormat = sheet.getCell(cell);
      titleCellFormat.style = {
        font: { bold: true, size: 15, color: { argb: 'ffffff' } },
        fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: dataTable.subHeaders.color } },
        alignment: { vertical: 'middle', horizontal: 'center', wrapText: true },
        border: { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }

      };
    });

    const rowsToInsert = sheet.getRows(dataTable.bodyData.row, this.dataClone.length);
    let startRow = dataTable.bodyData.row;

    // Iterar sobre los datos clonados de forma síncrona para asegurar el orden
    for (let i = 0; i < this.dataClone.length; i++) {
      const element = this.dataClone[i];
      const row = rowsToInsert[i];

      row.values = element;

      const cellFormats2 = dataTable.columns.map((column, index) => index > 0 ? column.name + startRow : null).filter(cell => cell !== null);

      // Iterar sobre los formatos de celda de forma síncrona
      for (const cell of cellFormats2) {
        const titleCellFormat = sheet.getCell(cell);
        titleCellFormat.style = {
          font: { size: 12, color: { argb: '000000' } },
          alignment: { vertical: 'middle', horizontal: 'center', wrapText: true },
          border: { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
        };
      }

      sheet.getRow(startRow).height = dataTable.bodyData.height;

      startRow++;
    }
  }

  async getBase64ImageFromUrl(imageUrl) {
    // console.log("Downloading image...");
    let res = await fetch(imageUrl);
    let blob = await res.blob();

    const result = await new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.addEventListener("load", function () {
        resolve(reader.result);
      }, false);

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })

    return result
  }


  async makeSaleReports(salesOfTheDay, messageInvoiceEMMI, user, allProducts, data) {
    let itemsCotizacion = [];
    let total_cotizacion = 0;
    let total_cotizacion_iva = 0;
    let total_cotizacion_without_iva = 0;

    // Procesa cada artículo en salesOfTheDay.items
    await Promise.all(salesOfTheDay.items.map(async order => {
      let [data_products] = allProducts.filter(item => item.uid === order.uid);
      let data_products_product_emmi = data_products.product_emmi;

      if (data_products_product_emmi) {
        order.name = data_products.name;
        total_cotizacion += order.total;
        total_cotizacion_iva += order.total_iva;
        total_cotizacion_without_iva += order.total_without_iva;

        if (Array.isArray(data_products_product_emmi) && order.extras.length > 0) {
          let matchExtra = [];
          let new_product_emmi = {};

          await Promise.all(order.extras.map(async (element, index) => {
            let optionID = element.optionID;
            let subOptionID = element.subOptionID;

            let condition1 = data_products.extra_options;
            let condition2 = condition1[optionID];

            if (condition1 && condition2 && condition2.hasOwnProperty('activeEMMI') && condition2.activeEMMI === true) {
              if (condition2.subOptions && condition2.subOptions[subOptionID] && condition2.subOptions[subOptionID]['is_default']) {
                matchExtra.push(optionID + ":" + subOptionID);
              }
            }

            if (index === (order.extras.length - 1)) {
              const match1 = matchExtra.join();
              const [filter] = data_products_product_emmi.filter(item => item.extrasIds.join() === match1);

              if (filter) {
                new_product_emmi = filter;
              }
              else {
                new_product_emmi = data_products_product_emmi[0]
              }
            }
          }));

          data_products_product_emmi = { ...new_product_emmi };
        }
        else if (Array.isArray(data_products_product_emmi) && order.extras.length === 0) {
          data_products_product_emmi = { ...data_products_product_emmi[0] };
        }
        // let price_iva = this.toFixed((data_products.price - (data_products.price * 0.13)), 2);
        let comment1 = (order.is_tasting) ? 'Degustación' : '';
        let comment2 = (order.is_member) ? ('Descuento  de membresía | precio de venta: $' + data_products.price) : '';
        let AllComment = comment1 + ((comment1 !== '' && comment2 !== '') ? ' | ' : '') + comment2;
        itemsCotizacion.push({
          ...order,
          comment: AllComment,
          ...data_products_product_emmi
        });
      }
    }));

    // Obtiene configuración de Firestore
    await this.firestore.getOne('settings', 'admin').subscribe(async res => {
      if (res.productoEMMI) {
        this.productoEMMI = res.productoEMMI;

        if (salesOfTheDay.quantityExtras > 0 || salesOfTheDay.extras.length > 0) {
          // let iva = salesOfTheDay.totalExtras * 0.13;
          // total_cotizacion += salesOfTheDay.totalExtras;
          // total_cotizacion_iva += iva;
          // total_cotizacion_without_iva += salesOfTheDay.totalExtras - iva;
          // let price = salesOfTheDay.totalExtras / salesOfTheDay.quantityExtras;

          // itemsCotizacion.push({
          //   quantity: salesOfTheDay.quantityExtras,
          //   total: salesOfTheDay.totalExtras,
          //   total_without_iva: salesOfTheDay.totalExtras - iva,
          //   total_iva: iva,
          //   price: price,
          //   price_iva: price * 0.13,
          //   price_without_iva: price - (price * 0.13),
          //   comment: salesOfTheDay.commentExtras,
          //   ...this.productoEMMI.productExtras
          // });
          await salesOfTheDay.extras.forEach(async order => {
            total_cotizacion += order.total;
            total_cotizacion_iva += order.total_iva;
            total_cotizacion_without_iva += order.total_without_iva;

            itemsCotizacion.push({
              ...order,
              comment: order.name,
              ...this.productoEMMI.productExtras
            });
          });
        }

        if (salesOfTheDay.quantityFlete > 0) {
          let iva = salesOfTheDay.totalFlete - (salesOfTheDay.totalFlete / 1.13);
          total_cotizacion += salesOfTheDay.totalFlete;
          total_cotizacion_iva += iva;
          total_cotizacion_without_iva += salesOfTheDay.totalFlete - iva;
          let price = salesOfTheDay.totalFlete / salesOfTheDay.quantityFlete;

          itemsCotizacion.push({
            quantity: salesOfTheDay.quantityFlete,
            total: salesOfTheDay.totalFlete,
            total_without_iva: salesOfTheDay.totalFlete - iva,
            iva: iva,
            price: price,
            price_iva: price - (price / 1.13),
            price_without_iva: price / 1.13,
            comment: salesOfTheDay.commentFlete,
            ...this.productoEMMI.productFlete
          });
        }
      }
    });

    // Espera antes de continuar con la creación del reporte
    setTimeout(async () => {
      messageInvoiceEMMI.items = [];

      await Promise.all(itemsCotizacion.map(async element => {
        messageInvoiceEMMI.items.push({
          pk_quote_kind: messageInvoiceEMMI.pk_quote_kind,
          pk_storage: messageInvoiceEMMI.pk_storage,
          ...element
        });
      }));

      // Configura la factura externa
      messageInvoiceEMMI.external_invoice = 2; // external_invoice = 1 (factura de venta) | 0 (solicitud de compra) | 2 (factura de venta con IVA) 
      messageInvoiceEMMI.calculate_amounts = 0; // calculate_amounts = 0 (factura de venta) | 1 (solicitud de compra)
      messageInvoiceEMMI.total_cotizacion = total_cotizacion;
      messageInvoiceEMMI.total_cotizacion_iva = total_cotizacion_iva;
      messageInvoiceEMMI.total_cotizacion_without_iva = total_cotizacion_without_iva;

      // console.log("messageInvoiceEMMI POST:", messageInvoiceEMMI);

      // Envía los productos a EMMI
      try {
        this.http.sendPOST_ProductsEMMI({ ...messageInvoiceEMMI }).then((res: any) => {
          // console.log("sendPOST_ProductsEMMI:", res);
          if (res.success) {
            const log = {
              code: '',
              user_uid: user.uid,
              user_email: user.email,
              created_at: new Date(),
              active: true,
              ...messageInvoiceEMMI,
              ...res
            };
            log.code = this.firestore.getUid(messageInvoiceEMMI);
            log.code = log.code.substring(0, 6).toUpperCase();
  
            // Guarda el reporte de ventas en Firestore
            this.firestore.save("sales_reports_EMMI", { ...log }).then(save => { });
          } else {
            const log = {
              code: '',
              user_uid: user.uid,
              user_email: user.email,
              created_at: new Date(),
              active: true,
              ...messageInvoiceEMMI,
              ...res,
              success: false,
            };
            log.code = this.firestore.getUid(messageInvoiceEMMI);
            log.code = log.code.substring(0, 6).toUpperCase();
  
            // Guarda el reporte de ventas en Firestore
            this.firestore.save("sales_reports_EMMI", { ...log }).then(save => { });
            this.unSentInvoices(data);
          }
        }).catch((err) => {
          const log = {
            code: '',
            user_uid: user.uid,
            user_email: user.email,
            created_at: new Date(),
            active: true,
            ...messageInvoiceEMMI,
            ...err,
            success: false,
          };
          log.code = this.firestore.getUid(messageInvoiceEMMI);
          log.code = log.code.substring(0, 6).toUpperCase();
  
          // Guarda el reporte de ventas en Firestore
          this.firestore.save("sales_reports_EMMI", { ...log }).then(save => { });
          this.unSentInvoices(data);
        });
      } catch (error) {

      }
    }, 300);
  }

  toFixed(num, fixed) {
    return Number(num.toFixed(4));
  }

  unSentInvoices(data) {
    const log = {
      uid: '',
      created_at: new Date(),
      ...data
    };
    log.uid = this.firestore.getUid(log);

    // Guarda los reportes de ventas que no se enviaron a EMMI
    this.firestore.save("unsent_invoices", { ...log }).then(save => { });
  }
}
